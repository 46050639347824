import { useTranslation } from 'react-i18next'

import { Icon } from '@biogroup/icons'

export function SentConfirmation({ success }: { success: boolean }) {
  const { t } = useTranslation()
  return (
    <div className="mb-14 flex flex-col gap-y-2">
      {success ? (
        <div className="flex h-4 w-4 items-center justify-center rounded-full bg-feedback-success text-white">
          <Icon name="check" size="xs" />
        </div>
      ) : null}
      {success ? (
        <p className="text-md font-semibold text-muted-foreground">
          {t('sent.success')}
        </p>
      ) : (
        <p className="text-md font-semibold text-feedback-error">
          {t('sent.failed')}
        </p>
      )}
    </div>
  )
}
